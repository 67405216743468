















































import { Vue, Component, Prop } from "vue-property-decorator";
import AltIcon from "@/core/components/alt-icon";

@Component({
    name: "v-nav-menu-item",
    components: { AltIcon },
})
export default class VerticalNavMenuItem extends Vue {
    @Prop({ type: String, default: "" })
    private icon!: string;

    @Prop({ type: Boolean, default: false })
    private iconSmall!: boolean;

    @Prop({ type: String, default: "feather" })
    private iconPack!: string;

    @Prop({ type: [String /*, null*/], default: "#" })
    private href!: string | null;

    @Prop({ type: [String, Object /*, null*/], default: null })
    private to!: string | object | null;

    @Prop({ type: String, default: null })
    private route!: string;

    @Prop({ type: [String, Number], default: null })
    private index!: string | number;

    @Prop({ type: Boolean, default: true })
    private featherIcon!: boolean;

    @Prop({ type: String, default: "_self" })
    private target!: string;

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    private get visible(): boolean {
        return true;
    }

    private get isActive(): boolean {
        return this.route === this.$route.meta?.active;
    }
}

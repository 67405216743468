



















































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BSidebar, BBadge } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import AltLogo from "@/core/components/alt-logo";
import VNavMenuGroup from "./VerticalNavMenuGroup.vue";
import VNavMenuItem from "./VerticalNavMenuItem.vue";
import { IMenuItem } from "./menu-item";
import { Uuid } from "@/utils/uuid";

@Component({
    name: "v-nav-menu",
    components: {
        BSidebar,
        BBadge,
        VNavMenuGroup,
        VNavMenuItem,
        VuePerfectScrollbar,
        AltLogo,
    },
})
export default class VerticalNavMenu extends Vue {
    @Prop({ type: String })
    private logo!: string;

    @Prop({ type: Boolean, default: false })
    private openGroupHover!: boolean;

    @Prop({ type: String })
    private parent!: string;

    @Prop({ type: Array, required: true })
    private navMenuItems!: any[];

    @Prop({ type: String })
    private title!: string;

    private get context(): Vue {
        return this;
    }

    private sidebarMobile = false;
    private sidebarActive = false;
    private sidebarOpened = true;
    private sidebarHover = false;
    private clickNotClose: boolean = false; // disable close navMenu on outside click
    private isMouseEnter: boolean = false;
    private showCloseButton: boolean = false; // show close button in smaller devices
    private settings: object = {
        // perfectScrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 1,
        swipeEasing: true,
    };
    private showShadowBottom: boolean = false;
    private menuItems: any[] = [];
    private menuUpdateKey = Uuid.new();

    private get isGroupActive(): any {
        return (item: any) => {
            const path = this.$route.fullPath;
            const routeParent = this.$route.meta ? this.$route.meta.parent : undefined;
            let open = false;

            const func = (item: any) => {
                if (item.submenu) {
                    item.submenu.forEach((item: any) => {
                        if (item.route === this.$route.meta?.active) {
                            open = true;
                        } else if (item.submenu) {
                            func(item);
                        }
                    });
                }
            };
            func(item);
            return open;
        };
    }

    // private get menuItemsUpdated(): any[] {
    //     const clone = this.navMenuItems.slice();

    //     for (const [index, item] of this.navMenuItems.entries()) {
    //         if (item.header && item.items.length && (index || 1)) {
    //             const i = clone.findIndex(ix => ix.header === item.header);
    //             for (const [subIndex, subItem] of item.items.entries()) {
    //                 clone.splice(i + 1 + subIndex, 0, subItem);
    //             }
    //         }
    //     }

    //     return clone;
    // }

    public created(): void {
        this.sidebarMobile = this.$info.ui.windowWidth < 1200;
    }

    @Watch("$route")
    private onRouteChanged(): void {
        if (this.sidebarActive && this.showCloseButton) {
            this.sidebarActive = false;
        }
    }

    private onMenuSwipe(event: any): void {
        if (event.direction === 2) {
            // Swipe Left
            if (this.sidebarActive && this.showCloseButton) {
                this.sidebarActive = false;
            }
        }
    }

    private onSwipeAreaSwipe(event: any): void {
        if (event.direction === 4) {
            // Swipe Right
            if (!this.sidebarActive && this.showCloseButton) {
                this.sidebarActive = true;
            }
        }
    }

    private psSectionScroll(): void {
        const scroll_el = (this.$refs.verticalNavMenuPs as any).$el || this.$refs.verticalNavMenuPs;
        this.showShadowBottom = scroll_el.scrollTop > 0;
    }

    private mouseEnter(): void {
        this.isMouseEnter = true;
        if (!this.sidebarOpened) {
            this.sidebarHover = true;
        }
    }

    private mouseLeave(): void {
        this.isMouseEnter = false;
        if (!this.sidebarOpened) {
            this.sidebarHover = false;
        }
    }

    private setVerticalNavMenuWidth(): void {
        if (this.$info.ui.windowWidth >= 1200) {
            // Open NavMenu
            this.sidebarMobile = false;
            this.sidebarActive = true;

            // Menu Action buttons
            this.clickNotClose = true;
            this.showCloseButton = false;

            return;
        }

        // Close NavMenu
        this.sidebarMobile = true;
        this.sidebarActive = false;

        // Menu Action buttons
        this.showCloseButton = true;
        this.clickNotClose = false;
    }

    private async toggleOpened(): Promise<void> {
        try {
            this.sidebarOpened = !this.sidebarOpened;
            this.setVerticalNavMenuWidth();
            await this.$settings.setSidebarOpened(this.sidebarOpened);

            // TODO: сделать сохранение настроек для админов
            if (process.env.VUE_APP_ADMIN_MODE) {
                return;
            }
        } catch {}
    }

    public async mounted(): Promise<void> {
        try {
            this.sidebarOpened = await this.$settings.getSidebarOpened();
            this.setVerticalNavMenuWidth();

            this.$settings.onSettingsChanged = async () => {
                this.sidebarOpened = await this.$settings.getSidebarOpened();
            };

            this.$info.ui.addOpenSidebarChangedHandler(() => {
                this.sidebarActive = true;
            });

            this.$info.ui.addUpdateSidebarChangedHandler(async () => {
                await this.updateMenu();
            });

            this.$info.ui.addWindowWidthChangedHandler(() => {
                this.setVerticalNavMenuWidth();
            });

            this.$nextTick(async () => {
                await this.updateMenu();
            });
        } catch {}
    }

    private async updateMenu(): Promise<void> {
        this.menuItems = (await this.mapMenuItems(this.navMenuItems)) ?? [];

        this.$nextTick(async () => {
            this.menuUpdateKey = Uuid.new();
        });
    }

    private async mapMenuItems(menuItems?: IMenuItem[]): Promise<any[] | undefined> {
        if (!menuItems) {
            return undefined;
        }

        const items: any[] = [];

        for (const menuItem of menuItems) {
            const visible = menuItem.visible ? await menuItem.visible(this.context) : false;

            if (!visible) {
                continue;
            }

            items.push({
                name: this.$t(menuItem.i18n) || menuItem.name,
                iconPack: menuItem.iconPack,
                icon: menuItem.icon,
                route: menuItem.route,
                submenu: await this.mapMenuItems(menuItem.submenu),
            });
        }

        return items;
    }
}
